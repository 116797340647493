body {
  background-image: url('../src/assets/images/bg.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

a {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}

.active {
  color: blue !important;
}

.modal-body img {
  max-width: 100%;
  height: auto;
}

.modal-body table {
  width: 100%;
}

.modal-body table tr {
  background-color: #3399ff;
  color: white;
}

.modal-body table td,
.modal-body table th {
  border: 1px solid white;
  font-weight: 400;
  padding-top: 10px;
  padding-bottom: 10px;
}

.modal-body table p {
  margin-bottom: 0px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #dfdfdf;
}

::-webkit-scrollbar-thumb {
  background: #0185E8;
  border-radius: 999px;
}

::-webkit-scrollbar-thumb:hover {
  background: #005da5;
}